import 'core-js/stable'
import 'regenerator-runtime/runtime'

import style from '../css/front/style';
console.log(window.sales_pages_style_version);
if (window.sales_pages_style_version === 'v1') {
  console.log('Sales pages style version is v1');
  style;
} else {
  console.log('Sales pages style version is v2');
}

import Rails from '@rails/ujs';
// noinspection ES6UnusedImports
import App from '../js/stars.vue';

Rails.start();

require('../js/polyfills.es6');
require('../js/init.es6');

require('../js/accordion.es6');
require('../js/carousels.es6');
require('../js/fixel.es6');
require('../js/gallery.es6');
require('../js/helpers.es6');
require('../js/ismobile.es6');
require('../js/inview.es6');
require('../js/nav.es6');
require('../js/popup.es6');
require('../js/quantity.es6');
require('../js/countdown.es6');
require('../js/scrollpos.es6');
